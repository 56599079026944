import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom'; // Changed this line
import { clusterApiUrl } from '@solana/web3.js';
import App from './App';
import './index.css';
// Add this line for the wallet modal styles
import '@solana/wallet-adapter-react-ui/styles.css';

const root = createRoot(document.getElementById('root'));
const endpoint = clusterApiUrl('devnet');
const wallets = [new PhantomWalletAdapter()];

root.render(
  <ConnectionProvider endpoint={endpoint}>
    <WalletProvider wallets={wallets} autoConnect>
      <WalletModalProvider>
        <App />
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
);