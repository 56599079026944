import React, { useState, useEffect } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import * as web3 from '@solana/web3.js';
import { Loader, Copy } from 'lucide-react';

const ProfileView = ({ setShowProfile, recipientPublicKey, SOL_PRICE }) => {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [balance, setBalance] = useState(0);
  const [sendAmount, setSendAmount] = useState('');
  const [recipient, setRecipient] = useState('');
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [action, setAction] = useState('send'); // 'send' or 'receive'
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    if (wallet.publicKey) {
      handleLoadBalance();
      setIsManager(wallet.publicKey.toString() === recipientPublicKey.toString());
    }
  }, [wallet.publicKey]);

  const handleLoadBalance = async () => {
    if (wallet.publicKey) {
      try {
        setLoading(true);
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / web3.LAMPORTS_PER_SOL);
      } catch (error) {
        console.error('Error loading balance:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDisconnect = () => {
    wallet.disconnect();
    setShowProfile(false);
  };

  const handleSendSol = async () => {
    if (!wallet.publicKey || !sendAmount || !recipient) return;

    try {
      setLoading(true);
      const recipientPubKey = new web3.PublicKey(recipient);
      const lamports = sendAmount * web3.LAMPORTS_PER_SOL;

      const transaction = new web3.Transaction().add(
        web3.SystemProgram.transfer({
          fromPubkey: wallet.publicKey,
          toPubkey: recipientPubKey,
          lamports
        })
      );

      const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.lastValidBlockHeight = lastValidBlockHeight;
      transaction.feePayer = wallet.publicKey;

      const signedTransaction = await wallet.signTransaction(transaction);
      const signature = await connection.sendRawTransaction(signedTransaction.serialize());
      
      await connection.confirmTransaction({
        signature,
        blockhash,
        lastValidBlockHeight
      });

      // Add to transaction history
      setTransactions(prev => [{
        id: signature,
        type: 'Send',
        amount: `${sendAmount} SOL`,
        date: new Date().toISOString(),
        status: 'Completed',
        to: recipient.slice(0, 4) + '...' + recipient.slice(-4)
      }, ...prev]);

      setSendAmount('');
      setRecipient('');
      handleLoadBalance();
      
    } catch (error) {
      console.error('Error sending SOL:', error);
      setTransactions(prev => [{
        id: Date.now(),
        type: 'Send',
        amount: `${sendAmount} SOL`,
        date: new Date().toISOString(),
        status: 'Failed',
        to: recipient.slice(0, 4) + '...' + recipient.slice(-4)
      }, ...prev]);
    } finally {
      setLoading(false);
    }
  };

  const handleAddFunds = async () => {
    if (!wallet.publicKey) return;
    try {
      setLoading(true);
      const signature = await connection.requestAirdrop(
        wallet.publicKey,
        web3.LAMPORTS_PER_SOL
      );
      await connection.confirmTransaction(signature);
      
      setTransactions(prev => [{
        id: signature,
        type: 'Receive',
        amount: '1 SOL',
        date: new Date().toISOString(),
        status: 'Completed',
        from: 'Airdrop'
      }, ...prev]);

      handleLoadBalance();
    } catch (error) {
      console.error('Error adding funds:', error);
    } finally {
      setLoading(false);
    }
  };

  const copyAddress = () => {
    if (wallet.publicKey) {
      navigator.clipboard.writeText(wallet.publicKey.toString());
      alert('Wallet address copied to clipboard!');
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white rounded-lg p-8 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl">Profile {isManager && '(Manager)'}</h2>
          <button 
            onClick={handleDisconnect}
            className="text-red-500 hover:text-red-600"
          >
            Disconnect Wallet
          </button>
        </div>
        
        <div className="mb-6">
          <p className="text-gray-500 mb-2">Wallet Address</p>
          <div className="flex items-center">
            <p className="font-mono break-all">
              {wallet.publicKey ? wallet.publicKey.toString() : 'Not connected'}
            </p>
            {wallet.publicKey && (
              <button 
                onClick={copyAddress}
                className="ml-2 text-gray-500 hover:text-gray-700"
              >
                <Copy size={16} />
              </button>
            )}
          </div>
        </div>

        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <p className="text-gray-500">Balance</p>
            <button 
              onClick={handleLoadBalance}
              className="text-sm underline"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Refresh Balance'}
            </button>
          </div>
          <p className="text-2xl font-bold">{balance.toFixed(4)} SOL</p>
          <p className="text-sm text-gray-500">≈ ${(balance * SOL_PRICE).toFixed(2)} USD</p>
        </div>

        <div className="border-t pt-6">
          <div className="flex gap-4 mb-6">
            <button
              onClick={() => setAction('send')}
              className={`flex-1 py-2 px-4 rounded ${
                action === 'send' 
                  ? 'bg-black text-white' 
                  : 'border border-black text-black'
              }`}
            >
              Send
            </button>
            <button
              onClick={() => setAction('receive')}
              className={`flex-1 py-2 px-4 rounded ${
                action === 'receive' 
                  ? 'bg-black text-white' 
                  : 'border border-black text-black'
              }`}
            >
              Receive
            </button>
          </div>

          {action === 'send' ? (
            <div className="space-y-4">
              <div>
                <label className="block text-sm text-gray-500 mb-1">Recipient Address</label>
                <input
                  type="text"
                  value={recipient}
                  onChange={(e) => setRecipient(e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="Enter recipient's wallet address"
                />
              </div>
              <div>
                <label className="block text-sm text-gray-500 mb-1">Amount (SOL)</label>
                <input
                  type="number"
                  value={sendAmount}
                  onChange={(e) => setSendAmount(e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="Enter amount to send"
                  min="0"
                  step="0.1"
                />
                {sendAmount && (
                  <p className="text-sm text-gray-500 mt-1">
                    ≈ ${(sendAmount * SOL_PRICE).toFixed(2)} USD
                  </p>
                )}
              </div>
              <button
                onClick={handleSendSol}
                disabled={loading || !sendAmount || !recipient}
                className="w-full px-6 py-2 bg-black text-white rounded disabled:bg-gray-300 flex items-center justify-center"
              >
                {loading ? (
                  <>
                    <Loader size={16} className="animate-spin mr-2" />
                    Sending...
                  </>
                ) : (
                  'Send SOL'
                )}
              </button>
            </div>
          ) : (
            <div className="space-y-4">
              <div className="p-4 bg-gray-50 rounded">
                <p className="text-sm text-gray-500 mb-2">Your Wallet Address</p>
                <div className="flex items-center">
                  <p className="font-mono break-all">{wallet.publicKey?.toString()}</p>
                  <button 
                    onClick={copyAddress}
                    className="ml-2 text-gray-500 hover:text-gray-700"
                  >
                    <Copy size={16} />
                  </button>
                </div>
              </div>
              <button
                onClick={handleAddFunds}
                disabled={loading}
                className="w-full px-6 py-2 bg-black text-white rounded disabled:bg-gray-300 flex items-center justify-center"
              >
                {loading ? (
                  <>
                    <Loader size={16} className="animate-spin mr-2" />
                    Processing...
                  </>
                ) : (
                  'Request Test SOL (Devnet)'
                )}
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg p-8">
        <h3 className="text-xl mb-4">Transaction History</h3>
        <div className="space-y-4">
          {transactions.length > 0 ? (
            transactions.map((tx) => (
              <div key={tx.id} className="flex justify-between items-center border-b border-gray-100 py-4 last:border-0">
                <div>
                  <div className="flex items-center gap-2">
                    <span className={`${
                      tx.type === 'Send' ? 'text-red-500' : 'text-green-500'
                    }`}>
                      {tx.type}
                    </span>
                    <span className="text-gray-500">•</span>
                    <span className="text-gray-500">{new Date(tx.date).toLocaleDateString()}</span>
                  </div>
                  <p className="text-sm text-gray-500">
                    {tx.type === 'Send' ? 'To: ' + tx.to : 'From: ' + tx.from}
                  </p>
                </div>
                <div className="text-right">
                  <p className="font-medium">{tx.amount}</p>
                  <p className={`text-sm ${
                    tx.status === 'Completed' ? 'text-green-500' : 'text-red-500'
                  }`}>
                    {tx.status}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center py-4">No transactions yet</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileView;