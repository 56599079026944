import React, { useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import '../App.css';
import podImage from '../assets/pod1_rename.png';

const NetworkView = () => {
  const [predictions] = useState([1, 2]);
  const wallet = useWallet();

  const InternalPrediction = ({ prediction }) => (
    <div className="bg-white p-4 rounded-lg mb-4">
      <div className="flex items-start gap-3 mb-2">
        <img src={prediction.avatar} alt="" className="w-8 h-8 rounded-full" />
        <div>
          <p className="mb-1">{prediction.text}</p>
          <p className="text-sm text-gray-500">
            by {prediction.author} who staked {prediction.stake} in sol
          </p>
          <p className="text-xs text-gray-400">{prediction.time}</p>
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <div className="flex gap-2">
          <button className="px-4 py-1 bg-green-100 rounded">Yes</button>
          <span className="text-sm text-gray-500">{prediction.yesPercentage}%</span>
        </div>
        <div className="flex gap-2">
          <span className="text-sm text-gray-500">{prediction.noPercentage}%</span>
          <button className="px-4 py-1 bg-red-100 rounded">No</button>
        </div>
      </div>
      <p className="text-sm text-gray-500 mt-2">{prediction.volume} in Volume</p>
    </div>
  );

  const internalPredictions = [
    {
      id: 1,
      author: 'yoab',
      avatar: '/placeholder-avatar.jpg',
      text: "I think Danny's probably not going to make this UFC fight at 5:30pm today?",
      stake: '$3',
      time: '3pm today',
      yesPercentage: 60,
      noPercentage: 40,
      volume: '2.1bil'
    },
    {
      id: 2,
      author: 'danny',
      avatar: '/placeholder-avatar.jpg',
      text: "yoab for sure wont meal prep by the end of the day today or tomorrow, i told that nigga that shit takes to much time",
      stake: '$20',
      time: '4:30pm yesterday',
      yesPercentage: 60,
      noPercentage: 40,
      volume: '2.1bil'
    }
  ];

  return (
    <div>
      <div className="flex justify-between items-start mb-4 text-xs sm:text-sm">
        <p className="max-w-[45%]">
          <span className="text-[#808080]">streamed </span>
          <span className="font-bold">Friday, October 18th, 2024</span>
        </p>
        <p className="max-w-[45%] text-right">
          <span className="text-[#808080]">NFT is $30 minting only 100 copies </span>
          <button className="text-black font-bold hover:underline">download NFT</button>
        </p>
      </div>
      
      <div className="relative mb-6">
        <img 
          src={podImage} 
          alt="Podcast thumbnail" 
          className="w-full rounded-lg object-cover" 
          style={{height: '547px'}} 
        />
        <div className="absolute bottom-4 left-4 bg-black bg-opacity-50 text-white px-2 py-1 rounded text-sm">
          1:00:03
        </div>
      </div>
      
      <h2 className="text-2xl mb-2">Episode 1: The Beginning</h2>
      <p className="text-base mb-4">
        Working through the Simpsons and there predictions, what dolphins might think of us and the future of both
        work and prediction markets and overall just good energy.
      </p>
      
      <div className="flex justify-between items-center mb-8">
        <button className="px-4 py-2 bg-gray-200 text-black rounded hover:bg-gray-300">
          Comments 10
        </button>
      </div>

      {/* Internal Predictions Section */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl">Internal Predictions</h3>
          <input 
            type="text" 
            placeholder="Search" 
            className="px-4 py-2 border border-gray-300 rounded"
          />
        </div>
        <div className="space-y-4">
          {internalPredictions.map(prediction => (
            <InternalPrediction key={prediction.id} prediction={prediction} />
          ))}
        </div>
      </div>
      
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
        <h3 className="text-2xl mb-2 sm:mb-0">Predictions</h3>
        <input 
          type="text" 
          placeholder="Search" 
          className="w-full sm:w-auto px-4 py-2 border border-gray-300 rounded"
        />
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {predictions.map((i) => (
          <div key={i} className="border border-gray-300 rounded-lg overflow-hidden bg-white p-4">
            <h4 className="mb-2">From Episode 1: The Beginning</h4>
            <div className="relative mb-4">
              <img 
                src={podImage} 
                alt="Podcast thumbnail" 
                className="w-full rounded-lg object-cover" 
                style={{height: '200px'}} 
              />
              <div className="absolute bottom-2 left-2 bg-black bg-opacity-50 text-white px-1 py-0.5 rounded text-xs">
                1:00:03
              </div>
            </div>
            <p className="mb-2">Can danny find 5 cat videos with a cat raising its middle finger at a human by the next pod?</p>
            <p className="text-sm text-gray-500 mb-2">Ends 31/10/2024 at 2:00 pm</p>
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
              <div className="bg-blue-600 h-2.5 rounded-full" style={{width: '60%'}}></div>
            </div>
            <div className="flex justify-between mb-2">
              <button className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">Yes 60%</button>
              <button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">No 40%</button>
            </div>
            <p className="text-sm text-gray-500">2.1bil in Volume</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NetworkView;