import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Wallet } from 'lucide-react';
import NetworkView from './views/NetworkView';
import PeripheryView from './views/PeripheryView';
import ProfileView from './views/ProfileView';
import '@solana/wallet-adapter-react-ui/styles.css';

// Constants from LiveExperiment
const recipientPublicKey = 'Ai5oNL8qvQGE2dbYjzXahYvT44KoQ73zrUwsoPNr6PNB';
const SOL_PRICE = 184.56;

function App() {
  const [view, setView] = useState('network');
  const [showProfile, setShowProfile] = useState(false);
  const wallet = useWallet();
  const [userBalance, setUserBalance] = useState(0);
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    if (wallet.connected && wallet.publicKey) {
      setIsManager(wallet.publicKey.toString() === recipientPublicKey);
    }
  }, [wallet.connected, wallet.publicKey]);

  const handleProfileClick = () => {
    setShowProfile(!showProfile);
  };

  if (showProfile) {
    return (
      <div className="App bg-[#F5F5F5] min-h-screen font-mono text-black">
        <div className="max-w-[1200px] mx-auto px-2 sm:px-4 md:px-0">
          <header className="flex justify-between items-center mb-8 pt-4">
            <span className="text-lg">vervet</span>
            <div className="flex items-center space-x-6">
              <button 
                onClick={() => setShowProfile(false)}
                className="font-mono hover:underline"
              >
                back
              </button>
            </div>
          </header>
          <ProfileView 
            setShowProfile={setShowProfile} 
            recipientPublicKey={recipientPublicKey}
            SOL_PRICE={SOL_PRICE}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="App bg-[#F5F5F5] min-h-screen font-mono text-black">
      <div className="max-w-[1200px] mx-auto px-2 sm:px-4 md:px-0">
        <header className="flex justify-between items-center mb-8 pt-4">
          <span className="text-lg">vervet</span>
          <div className="flex items-center space-x-6">
            <div className="flex space-x-4">
              <button 
                onClick={() => setView('periphery')}
                className={`hover:underline ${view === 'periphery' ? 'font-bold' : ''}`}
              >
                periphery
              </button>
              <button 
                onClick={() => setView('network')}
                className={`hover:underline ${view === 'network' ? 'font-bold' : ''}`}
              >
                network
              </button>
            </div>
            <div className="wallet-connection flex items-center space-x-2">
              <Wallet size={24} className="mr-2" />
              {wallet.connected ? (
                <button 
                  className="font-mono hover:underline flex items-center"
                  onClick={handleProfileClick}
                >
                  profile ({userBalance} SOL) {isManager && '(manager)'}
                </button>
              ) : (
                <WalletMultiButton />
              )}
            </div>
          </div>
        </header>

        <div>
          <h1 className="text-4xl mb-2">Welcome to the future...</h1>
          <p className="text-xl mb-6">this is our {view === 'network' ? 'prediction' : 'information'} network</p>
          
          {view === 'network' ? <NetworkView /> : <PeripheryView />}
        </div>
      </div>
    </div>
  );
}

export default App;