import React, { useState, useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';

const GOOGLE_MAPS_API_KEY = 'YOUR_API_KEY'; // Replace with your valid API key
const MANAGER_PUBLIC_KEY = new PublicKey('Ai5oNL8qvQGE2dbYjzXahYvT44KoQ73zrUwsoPNr6PNB');

// Move loader initialization outside component
const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places'],
  authReferrerPolicy: 'origin'
});

const PeripheryView = () => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [location, setLocation] = useState({ lat: 40.7128, lng: -74.0060 }); // Default to NYC

  useEffect(() => {
    if (!mapRef.current || mapLoaded) return;

    const initializeMap = async () => {
      try {
        if (!GOOGLE_MAPS_API_KEY || GOOGLE_MAPS_API_KEY === 'YOUR_API_KEY') {
          throw new Error('Google Maps API key is missing or invalid');
        }

        const google = await loader.load();
        
        if (!mapRef.current) {
          console.error('Map container element not found');
          return;
        }

        const earthMap = new google.maps.Map(mapRef.current, {
          mapTypeId: 'hybrid',
          center: location,
          zoom: 18,
          tilt: 45,
          heading: 0,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.TOP_LEFT,
          },
          streetViewControl: false,
        });

        google.maps.event.addListenerOnce(earthMap, 'idle', () => {
          if (!mapRef.current) return;
          setMap(earthMap);
          setMapLoaded(true);

          const locationButton = document.createElement('button');
          locationButton.textContent = '📍 My Location';
          locationButton.className = 'custom-map-control-button px-4 py-2 bg-white rounded-lg shadow-lg hover:bg-gray-100 transition-colors';
          
          locationButton.addEventListener('click', () => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  };
                  
                  earthMap.setCenter(pos);
                  earthMap.setZoom(18);
                  setLocation(pos);
                },
                (error) => {
                  console.error('Error getting location:', error);
                }
              );
            }
          });

          const tiltButton = document.createElement('button');
          tiltButton.textContent = '🌎 Toggle 3D';
          tiltButton.className = 'custom-map-control-button px-4 py-2 bg-white rounded-lg shadow-lg hover:bg-gray-100 transition-colors mt-2';
          
          let is3DMode = true;
          tiltButton.addEventListener('click', () => {
            is3DMode = !is3DMode;
            earthMap.setTilt(is3DMode ? 45 : 0);
            tiltButton.textContent = is3DMode ? '🌎 Toggle 2D' : '🌎 Toggle 3D';
          });

          earthMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(locationButton);
          earthMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(tiltButton);
        });

      } catch (error) {
        console.error('Error loading Google Maps:', error);
        if (mapRef.current) {
          mapRef.current.innerHTML = `
            <div class="flex items-center justify-center h-full bg-gray-100">
              <p class="text-red-500 p-4 text-center">
                Failed to load Google Maps. Error: ${error.message}<br>
                Please check your API key and ensure it's properly configured.
              </p>
            </div>
          `;
        }
      }
    };

    const timeoutId = setTimeout(() => {
      initializeMap();
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      if (map) {
        setMap(null);
        setMapLoaded(false);
      }
    };
  }, [location, mapLoaded, map]);

  useEffect(() => {
    const styles = `
      .custom-map-control-button {
        margin: 10px;
        font-family: system-ui, -apple-system, sans-serif;
        font-size: 14px;
        cursor: pointer;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 6px rgba(0,0,0,0.3);
        padding: 8px 16px;
      }

      .custom-map-control-button:hover {
        background: #f1f1f1;
      }
    `;

    const styleSheet = document.createElement('style');
    styleSheet.textContent = styles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div>
      <div className="bg-white rounded-lg p-6 mb-8">
        <h2 className="text-xl mb-4">Information Map</h2>
        <div 
          ref={mapRef} 
          className="h-96 rounded-lg overflow-hidden relative"
          style={{ 
            width: '100%', 
            height: '600px',
            minHeight: '400px' 
          }} 
        />
      </div>
    </div>
  );
};

export default PeripheryView;